<template>
  <div class="card card-custom gutter-b p-5" style="height: 90%">
    <h2 class="d-flex my-5">Petunjuk Test</h2>
    <div class="my-8 px-5">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vero iste?
      Impedit esse at vero? Voluptatum at laboriosam facilis nesciunt nemo,
      recusandae porro, consectetur assumenda soluta pariatur sint libero ipsam!
    </div>
    <div class="d-flex justify-content-center">
      <P>Jenis Soal</P>
    </div>
    <div class="d-flex justify-content-center">
      <P>Pilihan Ganda dan Benar atau Salah</P>
    </div>
    <div class="d-flex justify-content-center">
      <p>Jumlah Soal : 20 Butir</p>
    </div>
    <div class="d-flex justify-content-center mt-10">
      <!-- Nanti kayaknya tambahin fungsi biar kalau udah selesai tombol mulainya di disable -->
      <b-button to="/pretest"> Mulai </b-button>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "petunjukpre",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "" }]);
  },
};
</script>